<template>
  <el-dialog title="添加骑手评价" :visible.sync="dialogVisible" append-to-body custom-class="dialog" :before-close="handleCancel">
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" :loading="loading">确认</el-button>
    </span>
    <div v-loading="loading">
      <el-form :model="form" :rules="rules" ref="form" label-width="60px" label-position="right">
        <el-form-item label="运单号" >
          <span>{{form?.cSys}}</span>
        </el-form-item>
        <el-form-item label="订单号" >
          <span>{{form?.cOrderSys}}</span>
        </el-form-item>
        <el-form-item label="评分" prop="fAppraise">
          <el-rate v-model="form.fAppraise" style="margin-top: 10px"></el-rate>
        </el-form-item>
        <el-form-item label="备注" prop="cAppraise">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入，选填"
            v-model="form.cAppraise">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import {sexMap,payMap,settleMap} from "@/utils/deliveryMap";
import {getOrdFreightAppraise,setOrdFreightAppraise} from "@/api/delivery";

export default {
  name: "FreightSetting",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sid: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      loading:false,
      sexMap:sexMap,
      payMap:payMap,
      settleMap:settleMap,
      dialogVisible: this.visible,
      form: {
      },
      rules: {
        fAppraise: [
          { validator: this.validateRating, trigger: 'change' },
        ],
      },
    };
  },
  created() {
    //this.getOrdFreightAppraise();
  },
  mounted() {
  },
  methods: {
    getOrdFreightAppraise() {
      if(this.sid) {
        this.loading = true;
        getOrdFreightAppraise(this.sid).then(result => {
          if (result) {
            this.form = result.data;
            this.loading = false;
          }
        });
      }
    },
    validateRating(rule, value, callback) {
      if (value === 0) {
        callback(new Error('请至少选择一个星'));
      } else {
        callback();
      }
    },
    handleCancel() {
      this.$refs['form'].resetFields();
      this.dialogVisible = false;
    },
    handleConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          let params = this.form;
          params.sid = this.sid;
          setOrdFreightAppraise(params).then(result => {
            if (result) {
              this.$message.success("修改成功");
            }
          }).finally(() => {
            this.dialogVisible = false;
            this.$emit('close');
            this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  },
  watch: {
    visible(newVal) {
      if(newVal){
        this.getOrdFreightAppraise();
      }
      this.dialogVisible = newVal;
    },
    dialogVisible(newVal) {
      this.$emit('update:visible', newVal);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dialog{
  width: 400px!important;
}
::v-deep .el-dialog__body{
  padding: 20px 20px 30px 20px;
}
::v-deep .el-dialog {
  margin-top: 1vh!important;
}
.container{
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  .item{
    width: 100%;
    height: 40px;
    .title{
      width: 100px;
      height: 40px;
      line-height: 40px;
      float: left;
      text-align: left;
    }
    .content{
      width: calc(100% - 100px);
      height: auto;
      line-height: 40px;
      float: left;
      text-align: right;
    }
  }
}
</style>
