<template>
  <el-dialog title="选择骑手" :visible.sync="dialogVisible" append-to-body custom-class="dialog" :before-close="handleCancel">
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确认</el-button>
    </span>
    <div class="container">
      <div class="right-container">
        <div class="toolbar">
          <el-input placeholder="查询关键字" v-model="keyword" :clearable="true" :style="{ width:'250px',float: 'right'}">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
        <el-table
          :data="riderList"
          highlight-current-row ref="table"
          @row-click="singleElection"
          v-loading="loading"
          max-height="465"
        >
          <el-table-column label="" align="center" width="30px" >
            <template #default="scope">
              <el-radio class="radio" v-model="templateSelection" :label="scope.$index" style="margin-left: 10px">&nbsp;</el-radio>
            </template>
          </el-table-column>
          <el-table-column label="序号" type="index" align="center" width="60px" :index="getIndex"></el-table-column>
          <el-table-column label="姓名" align="center" width="120px" prop="cName"></el-table-column>
          <el-table-column label="性别" align="center" width="60px" >
            <template #default="scope">
              {{ getSex(scope.row.bSex) }}
            </template>
          </el-table-column>
          <el-table-column label="手机号码" align="center" width="180px" prop="cPhone"></el-table-column>
        </el-table>
        <el-pagination
          background
          :layout="pageLayout"
          :total="total"
          :current-page="pageNum"
          :page-sizes="pageSelect"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :style="{marginTop: '15px'}">
        </el-pagination>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {getOrdFreightRiderList} from "@/api/delivery";
import {settleMap, sexMap, statusMap} from "@/utils/deliveryMap";
export default {
  name: "SelectRider",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading:false,
      dialogVisible: this.visible,
      keyword:'',
      riderList:[],
      templateSelection: '',
      selectedRow: null,
      total: 0, //总条数
      pageNum: 1, //当前页
      pageSize: 10, //每页显示条数
      pageSelect: [10, 20, 30, 50],
      pageLayout:"prev, pager, next,jumper,total,sizes",
    };
  },
  created() {
  },
  mounted() {
    this.getOrdFreightRiderList();
  },
  computed: {
    getSex() {
      return function(bSex) {
        return sexMap[bSex] || '未知';
      };
    },
  },
  methods: {
    getOrdFreightRiderList() {
      this.templateSelection = '';
      this.selectedRow = null;
      let params = {
        // type:2,
        search:this.keyword,
        rStores:[],
        pageNum:this.pageNum,
        pageSize:this.pageSize,
      }
      this.loading = true;
      getOrdFreightRiderList(params).then(result => {
        if (result) {
          this.riderList = result.rows;
          this.total = result.total;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 查询
    search(){
      this.pageNum = 1;
      this.getOrdFreightRiderList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getOrdFreightRiderList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getOrdFreightRiderList();
    },
    view(item){
    },
    handleCancel() {
      this.dialogVisible = false;
      this.keyword = '';
    },
    singleElection(row){
      this.selectedRow = row;
    },
    handleConfirm() {
      if(!this.selectedRow){
        this.$message.warning("请选择骑手");
        return;
      }
      this.$emit('selectRider', {rRider:this.selectedRow.sid,riderName:this.selectedRow.cName,riderPhone:this.selectedRow.cPhone});
      this.keyword = '';
    },
    getIndex(rowIndex) {
      return (this.pageNum - 1) * this.pageSize + rowIndex +1;
    },
  },
  watch: {
    visible(newVal) {
      this.dialogVisible = newVal;
      if(newVal){
        this.getOrdFreightRiderList();
      }
    },
    dialogVisible(newVal) {
      this.$emit('update:visible', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dialog{
  width: 490px!important;
  height: 720px!important;
}
::v-deep .el-dialog__body{
  padding: 20px 20px 20px 20px;
}
::v-deep .el-dialog {
  margin-top: 1vh!important;
}
.cCustomerAddr{
  white-space: normal;
  text-align: left;
}
.container{
  width: 100%;
  height: 550px;
  background-color: #fff;
  //padding: 20px;
}
.right-container{
  width:100%;
  height: 100%;
}
.list-container{
  width: 100%;
  .toolbar{
    width: 100%;
    height: 50px;
  }
}
</style>
