<template>
  <el-dialog title="自配送发单" :visible.sync="dialogVisible" append-to-body custom-class="dialog" :before-close="handleCancel">
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" :loading="loading">确认</el-button>
    </span>
    <div v-loading="loading">
      <el-form :model="form" :rules="rules" ref="form" label-width="100px" label-position="left">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="系统单号" prop="cSys">
              <span>{{form.cSys}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="拣货单号" prop="rSys">
              <el-input v-model="form.rSys" disabled style="width:270px"></el-input>
              <span class="link" @click="openSelectOrdPicking">选择</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="收货人" prop="cCustomerName">
              <el-input v-model="form.cCustomerName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="cCustomerPhonePrivate">
              <el-input v-model="form.cCustomerPhonePrivate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="收货地址" prop="cCustomerAddr">
              <el-input v-model="form.cCustomerAddr"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top: 30px">
          <el-col :span="12">
            <el-form-item label="骑手姓名" prop="riderName">
              <el-input v-model="form.riderName" disabled style="width:270px"></el-input>
              <span class="link" @click="openSelectRider">选择</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="骑手电话" prop="riderPhone">
              <el-input v-model="form.riderPhone" disabled style="width:320px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="配送运费" prop="nDelivery">
              <el-input-number v-model="form.nDelivery" :precision="2" :step="1" :min="0" :max="10000" style="width:320px"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发单时间" prop="tCreate">
              <el-date-picker
                v-model="form.tCreate"
                type="datetime"
                placeholder="选择日期时间"
                style="width:320px"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <SelectOrdPicking :visible.sync="showSelectOrdPicking" @selectOrdPicking="selectOrdPicking"/>
    <SelectRider :visible.sync="showSelectRider" @selectRider="selectedRider"/>
  </el-dialog>
</template>

<script>
import {phoneValidator} from "@/utils/validator";
import {generateOrdFreightNumber,addOrdFreight} from "@/api/delivery";
import SelectOrdPicking from "@/views/components/DeliveryOrder/SelectOrdPicking";
import SelectRider from "@/views/components/DeliveryOrder/SelectRider";

export default {
  name: "AddOrdFreightDetail",
  components: {SelectOrdPicking,SelectRider},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading:false,
      dialogVisible: this.visible,
      showSelectOrdPicking: false,
      showSelectRider: false,
      form: {
        cSys: "",
        rPicking: "",
        rSys: "",
        cCustomerName: "",
        cCustomerPhonePrivate: "",
        cCustomerAddr: "",
        rRider: "",
        riderName:"",
        riderPhone:"",
        nDelivery: 0,
        cRemark: "",
        tCreate:'',
      },
      rules: {
        rPicking: [
          { required: true, message: '请选择订单号', trigger: 'blur' },
        ],
        cCustomerName: [
          { required: true, message: '请填写收货人', trigger: 'blur' },
          { max: 10, message: '长度不能超过200个字符', trigger: 'blur' }
        ],
        cCustomerPhonePrivate: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: phoneValidator, trigger: 'blur' },
        ],
        cCustomerAddr: [
          { required: true, message: '请填写收货地址', trigger: 'blur' },
          { max: 10, message: '长度不能超过200个字符', trigger: 'blur' }
        ],
        nDelivery: [
          { required: true, message: '请输入骑手姓名', trigger: 'blur' },
        ],
        riderName: [
          { required: true, message: '请输入骑手电话', trigger: 'blur' },
        ],
        riderPhone: [
          { required: true, message: '请填写配送运费', trigger: 'blur' },
        ],
        tCreate: [
          { required: true, message: '请填写发单时间', trigger: 'blur' },
        ],
      },
    };
  },
  created() {

  },
  mounted() {

  },
  methods: {
    generateOrdFreightNumber() {
      generateOrdFreightNumber().then(result => {
        if (result && result.code === 200) {
          this.form.cSys = result.msg;
        }
      });
    },
    handleCancel() {
      this.$refs['form'].resetFields();
      this.dialogVisible = false;
    },
    handleConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          let params = this.form;
          addOrdFreight(params).then(result => {
            if (result) {
              this.$message.success("自配送发单成功");
              this.dialogVisible = false;
              this.$emit('close');
            }
          }).finally(() => {
            this.$refs['form'].resetFields();
            this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    openSelectOrdPicking(){
      this.showSelectOrdPicking = true;
    },
    openSelectRider(){
      this.showSelectRider = true;
    },
    selectOrdPicking(e){
      this.form.rPicking = e.rPicking;
      this.form.rSys = e.rSys;
      this.showSelectOrdPicking = false;
    },
    selectedRider(e){
      this.form.rRider = e.rRider;
      this.form.riderName = e.riderName;
      this.form.riderPhone = e.riderPhone;
      this.showSelectRider = false;
    },
  },
  watch: {
    visible(newVal) {
      if(newVal){
        this.generateOrdFreightNumber();
      }
      this.dialogVisible = newVal;
    },
    dialogVisible(newVal) {
      this.$emit('update:visible', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dialog{
  width: 900px;
}
::v-deep .el-dialog {
  margin-top: 1vh!important;
}
.link{
  color:#409EFF;
  cursor: pointer;
  margin-left: 15px;
}
</style>
