<template>
  <div class="container">
    <SelectStore @selectStore="handleSelectStore"/>
    <div class="right-container">
      <div class="list-container" :style="{height: height}">
        <div class="header">自配送管理</div>
        <div class="toolbar">
          <el-button type="primary" @click="add" :style="{marginTop: '15px'}">自配送发单</el-button>
          <el-input placeholder="查询关键字" v-model="keyword" :clearable="true" :style="{ width:'250px',marginTop: '15px',float: 'right'}">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
        <el-table :data="ordFreightList" highlight-current-row ref="table" v-loading="loading">
          <el-table-column label="序号" type="index" align="center" width="60px" :index="getIndex"></el-table-column>
          <el-table-column label="门店名称" align="center" width="160px" prop="sStore"></el-table-column>
          <el-table-column label="配送单号" align="center" width="140px" >
            <template #default="scope">
              <div class="link" @click="view(scope.row)">{{ scope.row.cSys }}</div>
            </template>
          </el-table-column>
          <el-table-column label="骑手" align="center" width="80px" >
            <template #default="scope">
              <div class="link">{{ scope.row.sRider }}</div>
            </template>
          </el-table-column>
          <el-table-column label="骑手电话" align="center" width="100px" >
            <template #default="scope">
              <div class="link">{{ scope.row.cRiderPhone }}</div>
            </template>
          </el-table-column>
          <el-table-column label="运费" align="center" width="90px" prop="nTotal" :formatter="priceFormatter"></el-table-column>
          <el-table-column label="下单时间" align="center" width="130px" prop="tCreate" :formatter="dateFormatter"></el-table-column>
          <el-table-column label="配送状态" align="center" width="70px" >
            <template #default="scope">
              <div class="link">{{ getFreightStatus(scope.row.bStatus) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="结算" align="center" width="60px" >
            <template #default="scope">
              <div class="link">{{ scope.row.bStatus1 }}</div>
            </template>
          </el-table-column>
          <el-table-column label="完成时间" align="center" width="120px" prop="tComplete" :formatter="dateFormatter"></el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="120px">
            <template slot-scope="scope">
              <el-button type="text" @click="appraise(scope.row)">评价</el-button>
              <el-button type="text" @click="view(scope.row)" style="margin-right: 12px">详情</el-button>
              <el-dropdown>
                <span class="link">更多</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="cancel" @click.native.prevent="cancel(scope.row)">取消运单</el-dropdown-item>
                  <el-dropdown-item command="complete" @click.native.prevent="complete(scope.row)">完成运单</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :layout="pageLayout"
          :total="total"
          :current-page="pageNum"
          :page-sizes="pageSelect"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :style="{marginTop: '15px'}">
        </el-pagination>
        <ViewOrdPickingDetail :visible.sync="showViewOrdPickingDetail" :rPicking="currentRPicking" @close="handleCloseViewOrdPickingDetail"/>
        <ViewOrdFreightDetail :visible.sync="showViewOrdFreightDetail" :sid="currentSid" @close="handleCloseViewOrdFreightDetail"/>
        <FreightAppraiseSetting :visible.sync="showFreightAppraiseSetting" :sid="currentSid" @close="handleCloseFreightAppraiseSetting"/>
        <AddOrdFreightDetail :visible.sync="showAddOrdFreightDetail" @close="handleCloseAddOrdFreightDetail"/>
      </div>
    </div>
  </div>
</template>

<script>
import {getOrdFreightList, cancelOrdFreight, completeOrdFreight, deleteOrdFreightRider} from "@/api/delivery";
import {freightTypeMap,freightStatusMap} from "@/utils/deliveryMap";
import {formatTime} from "@/utils/utils";
import SelectStore from "@/views/components/SelectStore";
import ViewOrdPickingDetail from "@/views/components/DeliveryOrder/ViewOrdPickingDetail";
import ViewOrdFreightDetail from "@/views/components/DeliveryOrder/ViewOrdFreightDetail";
import FreightAppraiseSetting from "@/views/components/DeliveryOrder/FreightAppraiseSetting";
import AddOrdFreightDetail from "@/views/components/DeliveryOrder/AddOrdFreightDetail";

export default {
  name: "deliverySelfDeliveryOrder",
  components: {SelectStore,ViewOrdFreightDetail,ViewOrdPickingDetail,FreightAppraiseSetting,AddOrdFreightDetail},
  data() {
    return {
      height: document.documentElement.clientHeight - 60 + "px",
      currentSid:'',
      currentRPicking:'',
      showViewOrdPickingDetail: false,
      showViewOrdFreightDetail: false,
      showFreightAppraiseSetting: false,
      showAddOrdFreightDetail: false,
      rStores:[],
      keyword:'',
      ordFreightList:[],
      loading:false,
      total: 0, //总条数
      pageNum: 1, //当前页
      pageSize: 10, //每页显示条数
      pageSelect: [10, 20, 30, 50],
      pageLayout:"prev, pager, next,jumper,total,sizes",
    };
  },
  created() {

  },
  mounted() {

  },
  computed: {
    getFreightType() {
      return function(bType) {
        return freightTypeMap[bType] || '未知';
      };
    },
    getFreightStatus() {
      return function(bType) {
        return freightStatusMap[bType] || '未知';
      };
    },
  },
  methods: {
    getOrdFreightList() {
      let params = {
        type:2,
        search:this.keyword,
        rStores:this.rStores,
        pageNum:this.pageNum,
        pageSize:this.pageSize,
      }
      this.loading = true;
      getOrdFreightList(params).then(result => {
        if (result) {
          this.ordFreightList = result.rows;
          this.total = result.total;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 查询
    search(){
      this.pageNum = 1;
      this.getOrdFreightList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getOrdFreightList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getOrdFreightList();
    },
    handleSelectStore(e) {
      this.rStores = e;
      this.pageNum = 1;
      this.getOrdFreightList();
    },
    cancel(item){
      cancelOrdFreight(item.sid).then(result => {
        if (result) {
          this.getOrdFreightList();
          this.$message.success("操作成功");
        }
      });
    },
    complete(item){
      completeOrdFreight(item.sid).then(result => {
        if (result) {
          this.getOrdFreightList();
          this.$message.success("操作成功");
        }
      });
    },
    add(){
      this.showAddOrdFreightDetail = true;
    },
    view(item){
      this.currentSid = item.sid;
      this.showViewOrdFreightDetail = true;
    },
    appraise(item){
      this.currentSid = item.sid;
      this.showFreightAppraiseSetting = true;
    },
    viewOrdPicking(item){
      this.currentRPicking = item.rPicking;
      this.showViewOrdPickingDetail = true;
    },
    handleCloseViewOrdPickingDetail() {
      this.showViewOrdPickingDetail = false;
    },
    handleCloseViewOrdFreightDetail() {
      this.showViewOrdFreightDetail = false;
    },
    handleCloseFreightAppraiseSetting() {
      this.showFreightAppraiseSetting = false;
    },
    handleCloseAddOrdFreightDetail(){
      this.showAddOrdFreightDetail = false;
      this.pageNum = 1;
      this.getOrdFreightList();
    },
    getIndex(rowIndex) {
      return (this.pageNum - 1) * this.pageSize + rowIndex +1;
    },
    priceFormatter(row, column, cellValue) {
      return cellValue?'￥'+cellValue:'';
    },
    dateFormatter(row, column, cellValue) {
      return cellValue?formatTime(cellValue,"YYYY-MM-DD HH:mm"):'';
    },
  },
  watch: {
    rStores(newVal) {
      // this.pageNum = 1;
      // this.getOrdFreightList();
    },
  },
};
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
}
.right-container{
  float:left;
  width:calc(100% - 240px);
  height: 100%;
}
.list-container{
  width: 100%;
  border: 1px solid #ddd;
  padding: 0px 20px;
  .header{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    padding-left: 5px;
    border-bottom: 1px solid #ddd;
  }
  .toolbar{
    width: 100%;
    height: 60px;
  }
}
.link{
  color:#409EFF;
  cursor: pointer;
}
</style>
