<template>
  <el-dialog title="选择订单" :visible.sync="dialogVisible" append-to-body custom-class="dialog" :before-close="handleCancel">
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确认</el-button>
    </span>
    <div class="container">
      <SelectChannel @selectChannel="handleSelectChannel"/>
      <div class="right-container">
        <div class="toolbar">
          <el-input placeholder="查询关键字" v-model="keyword" :clearable="true" :style="{ width:'250px',float: 'right'}">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
        <el-table
          :data="ordPickingSelectWaitList"
          highlight-current-row ref="table"
          max-height="510"
          @row-click="singleElection"
          v-loading="loading">
          <el-table-column label="" width="30px" >
            <template #default="scope">
              <el-radio class="radio" v-model="templateSelection" :label="scope.$index" style="margin-left: 10px">&nbsp;</el-radio>
            </template>
          </el-table-column>
          <el-table-column label="流水号" align="center" width="60px" prop="sRetrieval"></el-table-column>
          <el-table-column label="拣货单号" align="center" width="120px" prop="rSys"></el-table-column>
          <el-table-column label="订单号" align="center" width="120px" prop="rOrder"></el-table-column>
          <el-table-column label="收货地址" align="center" width="220px" >
            <template #default="scope">
              <div class="cCustomerAddr">{{ scope.row.cCustomerAddr }}</div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="60px">
            <template slot-scope="scope">
              <el-button type="text" @click="view(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import SelectChannel from "@/views/components/SelectChannel";
import {getOrdPickingSelectWaitList} from "@/api/delivery";

export default {
  name: "SelectOrdPicking",
  components: {SelectChannel},
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading:false,
      dialogVisible: this.visible,
      rChannel:'',
      keyword:'',
      ordPickingSelectWaitList:[],
      templateSelection: '',
      selectedRow: null,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    getOrdPickingSelectWaitList() {
      this.templateSelection = '';
      this.selectedRow = null;
      let params = {
        search:this.keyword,
        rChannel:this.rChannel === 'all'?'':this.rChannel,
      }
      this.loading = true;
      getOrdPickingSelectWaitList(params).then(result => {
        if (result) {
          this.ordPickingSelectWaitList = result.data;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 查询
    search(){
      this.getOrdPickingSelectWaitList();
    },
    handleSelectChannel(e) {
      this.rChannel = e;
      this.getOrdPickingSelectWaitList();
    },
    view(item){
    },
    handleCancel() {
      this.dialogVisible = false;
      this.keyword = '';
    },
    singleElection(row){
      this.selectedRow = row;
    },
    handleConfirm() {
      if(!this.selectedRow){
        this.$message.warning("请选择订单");
        return;
      }
      this.$emit('selectOrdPicking', {rPicking:this.selectedRow.sid,rSys:this.selectedRow.rSys});
      this.keyword = '';
    }
  },
  watch: {
    visible(newVal) {
      this.dialogVisible = newVal;
      if(newVal){
        this.getOrdPickingSelectWaitList();
      }
    },
    dialogVisible(newVal) {
      this.$emit('update:visible', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dialog{
  width: 900px!important;
  height: 720px!important;
}
::v-deep .el-dialog__body{
  padding: 20px 20px 20px 20px;
}
::v-deep .el-dialog {
  margin-top: 1vh!important;
}
.cCustomerAddr{
  white-space: normal;
  text-align: left;
}
.container{
  width: 100%;
  height: 550px;
  background-color: #fff;
  //padding: 20px;
}
.right-container{
  float:left;
  width:calc(100% - 240px);
  height: 100%;
}
.list-container{
  width: 100%;
  .toolbar{
    width: 100%;
    height: 50px;
  }
}
</style>
