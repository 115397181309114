var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择订单",
        visible: _vm.dialogVisible,
        "append-to-body": "",
        "custom-class": "dialog",
        "before-close": _vm.handleCancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("SelectChannel", {
            on: { selectChannel: _vm.handleSelectChannel },
          }),
          _c(
            "div",
            { staticClass: "right-container" },
            [
              _c(
                "div",
                { staticClass: "toolbar" },
                [
                  _c(
                    "el-input",
                    {
                      style: { width: "250px", float: "right" },
                      attrs: { placeholder: "查询关键字", clearable: true },
                      model: {
                        value: _vm.keyword,
                        callback: function ($$v) {
                          _vm.keyword = $$v
                        },
                        expression: "keyword",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: { click: _vm.search },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "table",
                  attrs: {
                    data: _vm.ordPickingSelectWaitList,
                    "highlight-current-row": "",
                    "max-height": "510",
                  },
                  on: { "row-click": _vm.singleElection },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "", width: "30px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-radio",
                              {
                                staticClass: "radio",
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: scope.$index },
                                model: {
                                  value: _vm.templateSelection,
                                  callback: function ($$v) {
                                    _vm.templateSelection = $$v
                                  },
                                  expression: "templateSelection",
                                },
                              },
                              [_vm._v(" ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "流水号",
                      align: "center",
                      width: "60px",
                      prop: "sRetrieval",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "拣货单号",
                      align: "center",
                      width: "120px",
                      prop: "rSys",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "订单号",
                      align: "center",
                      width: "120px",
                      prop: "rOrder",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货地址",
                      align: "center",
                      width: "220px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "cCustomerAddr" }, [
                              _vm._v(_vm._s(scope.row.cCustomerAddr)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "right",
                      align: "center",
                      label: "操作",
                      width: "60px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.view(scope.row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }