export const phoneValidator = (rule, value, callback) => {
  const phoneRegex = /^1[3-9]\d{9}$/; // 中国大陆手机号正则表达式
  if (!value) {
    return callback(new Error('请输入手机号'));
  } else if (!phoneRegex.test(value)) {
    return callback(new Error('手机号格式不正确'));
  } else {
    callback();
  }
};
